'use client' // Error components must be Client Components

import { useEffect } from 'react'

import { InternalError } from '@/components/internal-error'
import { HttpMethod } from '@/common/api/rest/fetch'

const Error = ({
  error,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) => {
  useEffect(() => {
    fetch(`/api/v1/add-logs`, {
      method: HttpMethod.POST,
      body: JSON.stringify({
        place: 'app/error.tsx [useEffect]',
        context: {
          cause: error,
        },
      }),
    })
  }, [error])

  return <InternalError />
}
export default Error
